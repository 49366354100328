import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './IconLink.module.scss';
const cx = classNames.bind(styles);

const IconLink: FC<IconLinkProps> = ({ icon, href }) => {
  const className = 'icon-link';

  return (
    <a target="_blank" className={cx(className)} href={href} rel="noreferrer">
      <div className={cx(`${className}__icon`)}>{icon}</div>
    </a>
  );
};

export interface IconLinkProps {
  icon?: ReactNode;
  href: string;
  alt?: string;
}

export default IconLink;
