import React, { FC } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

import Button from '@/components/Button';
import Paragraph from '@/components/Paragraph';
import HomeLayout from '@/layouts/Home';
import Seo from '@/components/Seo';
import '@/theme/style.scss';
import { useGetSocialLinks } from '@/modules/socialLinks/socialLinks.hooks';
import IconLink from '@/components/IconLink';

const HomePage: FC = () => {
  const socialLinks = useGetSocialLinks();

  const renderLinks = socialLinks?.map((link, i) => {
    const icons = {
      github: <FaGithub />,
      linkedin: <FaLinkedin />,
    };

    return (
      <IconLink
        key={i}
        icon={!!link?.icon && icons[link.icon]}
        href={link.link ?? ''}
      />
    );
  });

  return (
    <>
      <Seo
        title="Rokas Anisas | Welcome"
        description="Rokas Anisas blog and projects portfolio"
      />
      <main>
        <HomeLayout links={renderLinks}>
          <Paragraph>
            Hello. I am Rokas Anisas. <br />I design and create web applications
          </Paragraph>
          <div>
            <Button
              label="Say hello"
              href="mailto:rokas.anisas@gmail.com?subject=Hey Rokas, I need some help building an app"
            />
          </div>
        </HomeLayout>
      </main>
    </>
  );
};

export default HomePage;
