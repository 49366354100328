import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './Button.module.scss';
const cx = classNames.bind(styles);

const Button: FC<ButtonProps> = ({ href, label }: ButtonProps) => {
  const className = 'button';

  const labelSplit = label.split(' ');
  const renderWords = (words: string[], uid: string) =>
    words?.map((word, i) => {
      return (
        <span
          className={cx(`${className}__word`, uid)}
          key={`${word}-${uid}-${i}`}
        >
          {word}
        </span>
      );
    });

  return (
    <a href={href} className={cx(className)}>
      <div className={cx(`${className}__inner-container`)}>
        {renderWords(labelSplit, 'one')}
        <div className={cx(`${className}__hover-words`)}>
          {renderWords(labelSplit, 'two')}
        </div>
      </div>
    </a>
  );
};

export interface ButtonProps {
  label: string;
  href?: string;
}

export default Button;
