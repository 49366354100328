import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './Paragraph.module.scss';
const cx = classNames.bind(styles);

const Paragraph: FC<ParagraphProps> = ({ children }: ParagraphProps) => {
  const className = 'paragraph';

  return <p className={cx(className)}>{children}</p>;
};

export interface ParagraphProps {
  children: string | ReactNode;
}

export default Paragraph;
