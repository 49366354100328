import React, { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

import logo from '@/assets/ra-logo.svg';

import styles from './HomeLayout.module.scss';
const cx = classNames.bind(styles);

const Home: FC<HomeProps> = ({ children, links }: HomeProps) => {
  const className = 'home-layout';

  return (
    <div className={cx(className)}>
      <div className={cx(`${className}__top`)} />
      <div className={cx(`${className}__content`)}>
        <img
          src={logo}
          className={cx(`${className}__logo`)}
          alt="rokas anisas logo"
        />
        {children}
      </div>
      <div className={cx(`${className}__bottom`)}>{links}</div>
    </div>
  );
};

interface HomeProps {
  children: ReactNode;
  links?: ReactNode;
}

export default Home;
