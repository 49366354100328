import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const Seo: FC<Props> = ({ title, description }) => {
  return (
    <Helmet
      title={title}
      meta={[
        { property: 'title', content: title },
        { property: 'description', content: description },
      ]}
      htmlAttributes={{
        lang: 'en',
      }}
    />
  );
};

interface Props {
  title?: string;
  description?: string;
}

export default Seo;
