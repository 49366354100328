import { useStaticQuery, graphql } from 'gatsby';

import { SocialLink } from './socialLinks.types';

export const useGetSocialLinks = () => {
  const data = useStaticQuery<{
    allSanitySocialLinks: { nodes: SocialLink[] | undefined };
  }>(graphql`
    query {
      allSanitySocialLinks {
        nodes {
          title
          link
          icon
        }
      }
    }
  `);

  return data?.allSanitySocialLinks?.nodes;
};
